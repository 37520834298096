import {
  Box,
  Flex,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';

const Terms = () => {
  // const OList = ({ list }) => {
  //   return (
  //     <OrderedList>
  //       {list?.map(({ title, desc, other, children }, i) => {
  //         return (
  //           <ListItem my={3} key={i}>
  //             <Text fontSize="xl" fontWeight={700}>
  //               {title}
  //             </Text>
  //             {desc}
  //             {other.length <= 1
  //               ? other[0].desc
  //               : other.map((item, i) => {
  //                   return <SubOList key={i} list={other} />;
  //                 })}
  //             {children}
  //           </ListItem>
  //         );
  //       })}
  //     </OrderedList>
  //   );
  // };
  // const SubOList = ({ list }) => {
  //   return (
  //     <OrderedList type="a">
  //       {list?.map(({ title, desc, other }, i) => {
  //         return (
  //           <ListItem my={3} key={i}>
  //             <Text>{title}</Text>
  //             {desc}
  //             {other}
  //           </ListItem>
  //         );
  //       })}
  //     </OrderedList>
  //   );
  // };
  // const UList = ({ list }) => {
  //   return (
  //     <UnorderedList>
  //       {list?.map(({ title, desc }, i) => {
  //         return (
  //           <ListItem my={3} key={i}>
  //             <strong>{title}</strong> {desc}
  //           </ListItem>
  //         );
  //       })}
  //     </UnorderedList>
  //   );
  // };

  // const SubSection = ({ subheading, contentList, list }) => {
  //   return (
  //     <Box>
  //       {subheading && (
  //         <Heading fontSize="xl" mt={5} mb={3}>
  //           {subheading}
  //         </Heading>
  //       )}
  //       {contentList?.map((content, i) => {
  //         return (
  //           <Text key={i} mt={3} mb={4}>
  //             {content || 'content'}{' '}
  //           </Text>
  //         );
  //       })}
  //       {list && <UList list={list} />}
  //     </Box>
  //   );
  // };

  // const Section = ({
  //   heading,
  //   note,
  //   subheading,
  //   contentList,
  //   list,
  //   children,
  // }) => {
  //   return (
  //     <Box mb={4}>
  //       <Heading fontSize="2xl" my={5}>
  //         {heading}
  //       </Heading>
  //       {note && (
  //         <Text fontStyle="italic" fontSize="sm" fontWeight={700}>
  //           {note}
  //         </Text>
  //       )}
  //       <SubSection
  //         subheading={subheading}
  //         contentList={contentList}
  //         list={list}
  //       />
  //       {children}
  //     </Box>
  //   );
  // };

  return (
    <>
      <Box w="full">
        <Flex
          flexDir="column"
          maxW={1200}
          margin="0 auto"
          px={[6, 8]}
          py={[8, 16]}
        >
          <Heading textAlign="center" fontSize="2xl">
            RESKILL AMERICANS TERMS OF SERVICE
          </Heading>
          <Text fontStyle="italic" fontWeight={700} my={3}>
            Last Updated: November 14
          </Text>

          <Box>
            <Text my={3}>
              These Terms of Service (“Terms”) apply to (i) your access to and
              use of the website located at reskillamericans.org and all
              associated web pages, websites, and social media pages (the
              “Site”) and (ii) your application to, enrollment in, and
              participation in software development and user experience and
              interface design training programs provided by RA (“Programs”).
              The Site and Programs (collectively, the “Services”) are provided
              by Reskill Americans (“RA”, “we”, “our” or “us”), and these Terms
              are an agreement between you and RA.
            </Text>
            <Text my={3}>
              BY AGREEING TO THESE TERMS, EXCEPT FOR (I) CERTAIN TYPES OF
              DISPUTES DESCRIBED IN SECTION 17, (II) WHERE YOU EXERCISE YOUR
              RIGHT TO OPT OUT OF ARBITRATION AS DESCRIBED IN SECTION 17, OR
              (III) TO THE EXTENT PROHIBITED BY LAW, DISPUTES BETWEEN YOU AND RA
              WILL BE RESOLVED SOLELY ON AN INDIVIDUAL BASIS AND NOT AS A CLASS
              ARBITRATION, CLASS ACTION, ANY OTHER KIND OF REPRESENTATIVE
              PROCEEDING, OR BY JURY TRIAL. If you do not agree to these Terms,
              do not use our Services.
            </Text>
            <Text my={3}>
              We may indicate that different or additional terms, conditions,
              guidelines, policies, or rules apply in relation to some of our
              Services (“Supplemental Terms”). Any Supplemental Terms become
              part of your agreement with us if you use the applicable Services,
              and if there is a conflict between these Terms and the
              Supplemental Terms, the Supplemental Terms will control solely to
              the extent of that conflict.
            </Text>
            <Text my={3}>
              We may make changes to these Terms. The “Last Updated” date above
              indicates when these Terms were last changed. If we make future
              changes, we may provide you with notice of such changes by any
              reasonable means, such as by sending an email, providing a notice
              through our Services, or updating the date at the top of these
              Terms. Unless we say otherwise in our notice, the amended Terms
              will be effective immediately, and your continued use of our
              Services after we provide such notice will confirm your acceptance
              of the changes. If you do not agree to the amended Terms, you must
              immediately stop using our Services.
            </Text>
          </Box>

          <OrderedList>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text my={3}>Your Information</Text>
              <Text fontWeight={400} fontSize="md">
                You may provide certain information to RA in connection with
                your access or use of our Services, or we may otherwise collect
                certain information about you when you access or use our
                Services. You agree to receive emails, SMS or text messages, and
                other types of communication from RA via the Services using the
                email address or other contact information you provide in
                connection with the Services. You represent and warrant that any
                information that you provide to RA in connection with the
                Services is accurate.
              </Text>
              <Text fontWeight={400} fontSize="md">
                For information about how we collect, use, share and otherwise
                process information about you, please see our Privacy Policy.
              </Text>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text my={3}>Eligibility </Text>
              <Text fontWeight={400} fontSize="md">
                If you are not at least 13 years of age you may not use any of
                our Services. There are, however, some Services (identified
                below) that you must be at least 18 years of age to use. For
                Services that users under 18 years of age are permitted to use,
                such users may only use our Services under the supervision of a
                parent or legal guardian who agrees to be bound by these Terms.
                The parent or legal guardian of a user under the age of 18 (or
                the age of legal majority) is fully responsible for the acts or
                omissions of such user in relation to our Services. If you are a
                parent or legal guardian and you believe that your child under
                the age of 18 is using our Services without your consent, please
                contact us at info@reskillamericans.org.
              </Text>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text my={3}>Accounts </Text>
              <OrderedList fontWeight={400} fontSize="md" type="a">
                <ListItem my={3}>
                  <strong>Creation and Security.</strong> You must create an
                  account with RA in order to use some of our Services. You will
                  provide accurate and complete account creation information and
                  promptly update any information contained in your account if
                  it changes. You may not share or permit others to use your
                  individual account credentials. You must maintain the security
                  of your account, as applicable, and promptly notify us if you
                  discover or suspect that someone has accessed your account
                  without your permission. If, in violation of the forgoing, you
                  share your account credentials or otherwise fail to secure
                  your account, you are responsible for all activities that
                  occur under your account as a result of that violation. Your
                  account is not transferable and in the event of your death,
                  incapacity, or unavailability, we may terminate any rights to
                  your account or User Content.
                </ListItem>
                <ListItem my={3}>
                  <strong>Linked Accounts.</strong> You may be required to link
                  your account from a third party in order to access our
                  Services. If you do link your third-party account, you
                  authorize RA to access your linked account’s information in
                  accordance with your settings with the third party. For more
                  information on how we use your information, see our
                  {'  '}
                  <Link
                    href="/privacy"
                    isExternal
                    style={{ color: 'blue', textDecoration: 'underline' }}
                  >
                    Privacy Policy
                  </Link>
                  .
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text my={3}>Program Participation</Text>
              <Text fontWeight={400} fontSize="md">
                The terms in this Section 4 apply to our Programs. Participation
                in Programs is limited to those users who are admitted by RA to
                the Program, and RA has sole discretion in deciding which users
                to admit to a Program.
              </Text>
              <OrderedList fontWeight={400} fontSize="md" type="a">
                <ListItem my={3}>
                  <strong>Minimum Applicant Eligibility.</strong> To apply to a
                  Program, you must meet the following minimum eligibility
                  requirements.
                  <UnorderedList>
                    <ListItem my={3}>
                      <strong>Age.</strong> Applicants must be at least 18 years
                      of age.
                    </ListItem>
                    <ListItem my={3}>
                      <strong>Location. </strong>Applicants must reside in the
                      United States and be legally authorized to work in the
                      United States.
                    </ListItem>
                    <ListItem my={3}>
                      <strong>Identity. </strong>Applicants must self-identify
                      as a historically underrepresented racial minority.
                    </ListItem>
                    <ListItem my={3}>
                      <strong>LinkedIn. </strong>Applicants must have a LinkedIn
                      profile that includes their full name, a picture, and
                      relevant work history.
                    </ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem my={3}>
                  <strong> Accuracy of Application.</strong> By applying to a
                  Program, you represent and warrant that (i) all information
                  provided in connection with your application is accurate and
                  complete and (ii) you meet all eligibility requirements for
                  the Program as stated in these Terms and as otherwise
                  communicated to you by RA. You will promptly notify RA if any
                  information provided in your application changes or if you no
                  longer meet the eligibility requirements for a Program you
                  have applied to or are enrolled in.
                </ListItem>
                <ListItem my={3}>
                  <strong>Revocation of Access. </strong>RA may suspend or
                  revoke your access to the Program at any time.
                </ListItem>
                <ListItem my={3}>
                  <strong>Recordings. </strong>RA may record live classes that
                  are offered as part of the Program and other online
                  interactions you have with RA personnel. By attending such a
                  live class or other online interaction, you consent to be part
                  of such recording. This means that information about you (such
                  as your name displayed in the attendee list) and your likeness
                  and voice may be captured in the recording. You authorize RA
                  to use the recordings for any purpose in connection with the
                  operation, development, or marketing of the Program or other
                  RA services. For more information on how we use your
                  information, please see our {'  '}
                  <Link
                    href="/privacy"
                    isExternal
                    style={{ color: 'blue', textDecoration: 'underline' }}
                  >
                    Privacy Policy
                  </Link>
                  .
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text my={3}>User Content</Text>
              <OrderedList fontWeight={400} fontSize="md" type="a">
                <ListItem my={3}>
                  Our Services may allow you and other users to create, post,
                  store, and share content, including messages, notes,
                  questions, comments, text, photos, videos, software, code, and
                  other materials (collectively, “User Content”). When you post
                  or otherwise share User Content on or through our Services
                  (including through Third-Party Services that you are using to
                  access our Services), you (i) understand that your User
                  Content and any associated information (such as your name or
                  profile photo) may be visible to others and (ii) grant RA the
                  license to User Content described in Section 5(b).
                </ListItem>
                <ListItem my={3}>
                  Except for the license you grant below, as between you and RA,
                  you retain all rights in and to your User Content, excluding
                  any portion of the Services included in your User Content. You
                  grant RA a perpetual, irrevocable, nonexclusive, royalty-free,
                  worldwide, fully paid, and sublicensable (through multiple
                  tiers) license to use, reproduce, modify, adapt, publish,
                  translate, create derivative works from, distribute, publicly
                  or otherwise perform and display, and exploit your User
                  Content and any name or likeness provided in connection with
                  your User Content in all media formats and channels now known
                  or later developed, without compensation to you or any third
                  party. You hereby irrevocably waive any “moral rights” or
                  other rights with respect to attribution of authorship or
                  integrity of materials regarding User Content that you may
                  have under any applicable law or under any legal theory.
                </ListItem>
                <ListItem my={3}>
                  You may not create, post, store, or share any User Content for
                  which you do not have all the rights necessary to grant us the
                  license described above, and you represent and warrant that
                  your User Content, and our use of such User Content as
                  permitted by these Terms, will not violate any rights of any
                  person or entity, including any third-party rights, or cause
                  injury to any person or entity. You may not create, post,
                  store, or share any User Content that:{' '}
                </ListItem>
                <UnorderedList>
                  <ListItem my={3}>
                    Is unlawful, libelous, defamatory, obscene, pornographic,
                    indecent, lewd, suggestive, harassing, threatening, invasive
                    of privacy or publicity rights, abusive, inflammatory, or
                    fraudulent;
                  </ListItem>
                  <ListItem my={3}>
                    Would constitute, encourage, or provide instructions for a
                    criminal offense, violate the rights of any party or
                    otherwise create liability, or violate any local, state,
                    national, or international law;
                  </ListItem>
                  <ListItem my={3}>
                    May infringe any patent, trademark, trade secret, copyright,
                    or other intellectual or proprietary right of any party;
                  </ListItem>
                  <ListItem my={3}>
                    Contains or depicts any statements, remarks, or claims that
                    do not reflect your honest views and experiences;
                  </ListItem>
                  <ListItem my={3}>
                    Impersonates, or misrepresents your affiliation with, any
                    person or entity;
                  </ListItem>
                  <ListItem my={3}>
                    Contains any unsolicited promotions, political campaigning,
                    advertising, or solicitations;
                  </ListItem>
                  <ListItem my={3}>
                    Contains any private or personal information of a third
                    party without such third party’s consent;
                  </ListItem>
                  <ListItem my={3}>
                    Contains any viruses, corrupted data or other harmful,
                    disruptive, or destructive files or content;
                  </ListItem>
                  <ListItem my={3}>Otherwise violates these Terms; or</ListItem>
                  <ListItem my={3}>
                    In our sole judgment, is objectionable, restricts or
                    inhibits any other person from using or enjoying our
                    Services, or may expose RA or others to any harm or
                    liability of any type.
                  </ListItem>
                </UnorderedList>
                <ListItem my={3}>
                  Enforcement of this Section 5 is solely at RA’s discretion,
                  and failure to enforce this section in some instances does not
                  constitute a waiver of our right to enforce it in other
                  instances. This Section 5 does not create any private right of
                  action on the part of any third party or any reasonable
                  expectation that the Services will not contain any content
                  that is prohibited by these Terms or that objectionable
                  material will be promptly removed after it has been posted.
                </ListItem>
                <ListItem my={3}>
                  We do not undertake to review all User Content, and we
                  expressly disclaim any duty or obligation to undertake any
                  monitoring or review of any User Content. Although we have no
                  obligation to screen, edit, or monitor User Content, we may:
                </ListItem>
                <UnorderedList>
                  <ListItem my={3}>
                    delete or remove User Content or refuse to post any User
                    Content at any time and for any reason with or without
                    notice, including without limitation for any violations of
                    applicable law or these Terms;
                  </ListItem>
                  <ListItem my={3}>
                    terminate or suspend your access to all or part of the
                    Services if your User Content is reasonably likely, in our
                    sole determination, to violate applicable law or these
                    Terms;
                  </ListItem>
                  <ListItem my={3}>
                    take any action with respect to your User Content that is
                    necessary or appropriate, in RA’s sole discretion, to ensure
                    compliance with applicable law and these Terms or to protect
                    any third-party rights, including third-party intellectual
                    property and privacy rights (e.g., providing information to
                    copyright owners in furtherance of Digital Millennium
                    Copyright Act takedown requests); and
                  </ListItem>
                  <ListItem my={3}>
                    cooperate fully with any law enforcement authorities or
                    court order requesting or directing us to disclose the
                    identity or other information of anyone posting any
                    materials on or through the Services. To learn more about
                    parental control protections (such as computer hardware,
                    software, or filtering services) that may assist you in
                    limiting your minor’s access to certain content, visit
                    https://www.fbi.gov/resources/parents.
                  </ListItem>
                </UnorderedList>
              </OrderedList>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text> Prohibited Conduct</Text>
              <OrderedList fontWeight={400} fontSize="md" type="a">
                <ListItem my={3}>
                  You will not use our Services if you are not eligible to use
                  our Services in accordance with Section 2 and will not use our
                  Services other than for their intended purpose. Further, you
                  will not, in connection with our Services:
                  <UnorderedList>
                    <ListItem my={3}>
                      Violate any applicable law, contract, intellectual
                      property right, or other third-party right or commit a
                      tort;
                    </ListItem>
                    <ListItem my={3}>
                      Engage in any harassing, threatening, intimidating,
                      predatory, or stalking conduct;
                    </ListItem>
                    <ListItem my={3}>
                      Share your password or otherwise put your account at risk;
                    </ListItem>
                    <ListItem my={3}>
                      Use or attempt to use another user’s account or
                      information without authorization from that user and RA;
                    </ListItem>
                    <ListItem my={3}>
                      Impersonate or post on behalf of any person or entity or
                      otherwise misrepresent your affiliation with a person or
                      entity;
                    </ListItem>
                    <ListItem my={3}>Sell or resell our Services;</ListItem>
                    <ListItem my={3}>
                      Copy, reproduce, distribute, publicly perform, or publicly
                      display all or portions of our Services, except as
                      expressly permitted by us or our licensors;{' '}
                    </ListItem>
                    <ListItem my={3}>
                      Modify our Services, remove any proprietary rights notices
                      or markings, or otherwise make any derivative works based
                      upon our Services;{' '}
                    </ListItem>
                    <ListItem my={3}>
                      Use our Services in any manner that could interfere with,
                      disrupt, negatively affect, or inhibit other users from
                      fully enjoying our Services or that could damage, disable,
                      overburden, or impair the functioning of our Services in
                      any manner;
                    </ListItem>
                    <ListItem my={3}>
                      Reverse engineer any aspect of our Services or do anything
                      that might discover source code, or bypass or circumvent
                      measures employed to prevent or limit access to any part
                      of our Services;
                    </ListItem>
                    <ListItem my={3}>
                      Use any data mining, robots, or similar data gathering or
                      extraction methods designed to scrape or extract data from
                      our Services except in accordance with instructions
                      contained in a robot.txt file on our Site and only to
                      compile for search results, provided that RA grants to the
                      operators of public search engines permission to use
                      spiders to copy materials from the Site for the sole
                      purpose of (and solely to the extent necessary for)
                      creating publicly available, searchable indices of such
                      materials, but not caches or archives of such materials.
                      RA reserves the right to revoke such permission either
                      generally or in specific cases, at any time and without
                      notice;{' '}
                    </ListItem>
                    <ListItem my={3}>
                      Develop or use any applications that interact with our
                      Services without our prior written consent;
                    </ListItem>
                    <ListItem my={3}>
                      Send, distribute, or post spam, unsolicited or bulk
                      commercial electronic communications, chain letters, or
                      pyramid schemes;
                    </ListItem>
                    <ListItem my={3}>
                      Use our Services to distribute any viruses, corrupted data
                      or other harmful, disruptive, or destructive files or
                      content;
                    </ListItem>
                    <ListItem my={3}>
                      Link to any online portion of the Services in a manner
                      that damages or exploits, in our sole discretion, our
                      reputation or suggests any form or association, approval,
                      or endorsement by RA;
                    </ListItem>
                    <ListItem my={3}>
                      Defraud RA or any person enrolled in the Program; or
                    </ListItem>
                    <ListItem my={3}>
                      Use our Services for any illegal or unauthorized purpose,
                      or engage in, encourage, or promote any activity that
                      violates these Terms.
                    </ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem my={3}>
                  Enforcement of this Section 6 is solely at RA’s discretion,
                  and failure to enforce this section in some instances does not
                  constitute a waiver of our right to enforce it in other
                  instances. Please note that parental control protections (such
                  as computer hardware, software, or filtering services) may be
                  commercially available to assist you in limiting access to
                  material that is harmful to people under 18 or the age of the
                  legal majority. Information identifying current providers of
                  such protections should be available at:
                  https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers
                  (last checked on 10/14/2022).
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text> Promotions</Text>
              <Text fontWeight={400} fontSize="md">
                Any sweepstakes, contests, raffles, surveys, games, or similar
                promotions (collectively, “Promotions”) made available through
                the Services may be governed by rules that are separate from
                these Terms. If you participate in any Promotions, please review
                the applicable rules as well as our Privacy Policy hyperlink. If
                the rules for a Promotion conflict with these Terms, the
                Promotion rules will govern solely to the extent of that
                conflict.
              </Text>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Ownership; Limited License</Text>
              <Text fontWeight={400} fontSize="md">
                The Services, including the text, graphics, images, photographs,
                videos, tutorials, exercises, illustrations, and other content
                contained therein, and all intellectual property rights therein
                and thereto, are owned by RA or our licensors and are protected
                under both United States and foreign laws. Except as explicitly
                stated in these Terms, all rights in and to the Services,
                including all intellectual property rights therein and thereto,
                are reserved by us or our licensors.
              </Text>
              <Text fontWeight={400} fontSize="md">
                Subject to your compliance with these Terms (including Sections
                5 and 6), you are hereby granted a limited, nonexclusive,
                nontransferable, non-sublicensable, revocable license to access
                and use our Services for your own personal, noncommercial use.
                Any use of the Services other than as specifically authorized
                herein, without our prior written permission, is strictly
                prohibited and will terminate the license granted herein and
                violate our intellectual property rights.
              </Text>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Trademarks</Text>
              <Text fontWeight={400} fontSize="md">
                The Reskill Americans name and our logos, our service names, our
                slogans and the look and feel of the Services are trademarks of
                RA and may not be copied, imitated or used, in whole or in part,
                without our prior written permission. All other trademarks,
                registered trademarks, product names, and company names or logos
                mentioned on or in connection with the Services are the property
                of their respective owners. Reference to any products, services,
                processes, or other information by trade name, trademark,
                manufacturer, supplier, or otherwise does not constitute or
                imply endorsement, sponsorship, or recommendation by us.
              </Text>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Feedback </Text>
              <Text fontWeight={400} fontSize="md">
                You may voluntarily post, submit, or otherwise communicate to us
                any questions, comments, suggestions, ideas, original or
                creative materials, or other information about RA or our
                Services (collectively, “Feedback”). You understand that we may
                use such Feedback for any purpose, commercial or otherwise,
                without acknowledgment or compensation to you, including to
                develop, copy, publish, or improve the Feedback or Services, or
                to improve or develop new products, services, or the Services in
                RA’s sole discretion. RA will exclusively own all improvements
                to, or new, RA products, services or Services based on any
                Feedback. You understand that RA may treat Feedback as
                nonconfidential.
              </Text>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Repeat Infringer Policy; Copyright Complaints</Text>
              <Text fontWeight={400} fontSize="md">
                In accordance with the Digital Millennium Copyright Act and
                other applicable law, we have adopted a policy of terminating,
                in appropriate circumstances, the accounts of users who
                repeatedly infringe the intellectual property rights of others.
                If you believe that anything on our Services infringes any
                copyright that you own or control, you may notify RA’s
                designated agent as follows:
              </Text>
              <Text fontWeight={400} fontSize="md">
                <strong>Designated Agent: </strong>Copyright Agent
              </Text>
              <Text fontWeight={400} fontSize="md">
                <strong>Telephone Number:</strong> (650) 260-4110
              </Text>
              <Text fontWeight={400} fontSize="md">
                <strong>Email Address: </strong> info@reskillamericans.org
              </Text>
              <Text fontWeight={400} fontSize="md">
                Please see 17 U.S.C. § 512(c)(3) for the requirements of a
                proper notification. If you knowingly materially misrepresent
                that any activity or material on our Services is infringing, you
                may be liable to RA for certain costs and damages.
              </Text>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Third-Party Services and Content</Text>
              <OrderedList fontWeight={400} fontSize="md">
                <ListItem my={3}>
                  Our Services rely on or interoperate with third-party products
                  and services, including, without limitation, data storage
                  services, chat and video services and other communications
                  technologies, third-party app stores, social media platforms,
                  and internet and mobile operators (collectively, “Third-Party
                  Services”). These Third-Party Services are beyond our control,
                  but their operation may impact, or be impacted by, the use and
                  reliability of our Services. You acknowledge that (a) the use
                  and availability of the Services is dependent on third-party
                  product vendors and service providers and (b) these
                  Third-Party Services may not operate reliably 100% of the
                  time, which may impact the way that our Services operate.
                </ListItem>
                <ListItem my={3}>
                  We may further provide information about or links to
                  third-party products, services, activities, or events, or we
                  may allow third parties to make their content and information
                  available on or through the Services (collectively,
                  “Third-Party Content”). We provide Third-Party Content as a
                  service to those interested in such content. Your dealings or
                  correspondence with third parties (including other users of
                  our Services) and your use of or interaction with any
                  Third-Party Content are solely between you and the third
                  party.
                </ListItem>
                <ListItem my={3}>
                  We have no obligation to monitor Third-Party Services or
                  Third-Party Content, and we may block or disable access to any
                  Third-Party Services or Third-Party Content (in whole or part)
                  through our Services at any time. Your access to and use of
                  such Third-Party Content or Third-Party Services may be
                  subject to additional terms, conditions, and policies
                  applicable to such Third-Party Content (including terms of
                  service or privacy policies of the providers of such
                  Third-Party Services). You are responsible for obtaining and
                  maintaining any computer hardware, equipment, network services
                  and connectivity, telecommunications services, and other
                  products and services necessary to access and use the
                  Services.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Donations</Text>
              <OrderedList fontWeight={400} fontSize="md">
                <ListItem my={3}>
                  <strong>Payment Processing.</strong> The Site may link you to
                  Third-Party Services that provide you with the opportunity to
                  make a one-time or recurring donation to RA. When making such
                  a donation, you will provide accurate and up-to-date payment
                  information and have sufficient funds or credit available on
                  your payment method to ensure that the purchase price or
                  donation amount will be collectible by us.
                  <br />
                  We have contracted with third-party payment processors to
                  facilitate donations. When you make a donation through a
                  Third-Party Service, you will provide your payment details and
                  any additional information required to complete your donation
                  directly to our third-party payment processors. Online payment
                  transactions may be subject to validation checks by our
                  payment processors and your card issuer, and we are not
                  responsible if your card issuer declines to authorize payment
                  for any reason. For your protection, our payment processors
                  may use fraud prevention protocols and verification systems to
                  reduce fraud, and you authorize them to verify and
                  authenticate your payment information. Please note, it is
                  possible that your card issuer may charge you an online
                  handling fee or processing fee. We are not responsible for any
                  such fees. In some jurisdictions, our payment processors may
                  use third parties under strict confidentiality and data
                  protection agreements for the purposes of payment processing
                  services.
                </ListItem>
                <ListItem my={3}>
                  <strong>Recurring Donations.</strong> You may sign-up to make
                  recurring donations to RA. Recurring donations may be made on
                  a monthly basis (“Donation Frequency”) for the amount
                  indicated at the time you sign-up (“Donation Amount”). You
                  agree that recurring donations are continuous until you cancel
                  them with the Third-Party Service you enrolled with. You will
                  be charged the Donation Amount both (a) when you first sign-up
                  for a recurring donation and (b) on the Donation Frequency
                  schedule you select. YOU HEREBY AUTHORIZE RA TO AUTOMATICALLY
                  CHARGE THE DONATION AMOUNT TO YOUR CREDIT CARD, DEBIT YOUR
                  BANK ACCOUNT, OR OTHERWISE BILL OR CHARGE YOU IN ACCORDANCE
                  WITH ANY PAYMENT METHOD YOU HAVE SELECTED, ON THE DONATION
                  FREQUENCY SCHEDULE SPECIFIED AT THE TIME OF SIGN-UP UNTIL YOU
                  CANCEL YOUR RECURRING DONATION. To stop a recurring donation,
                  update your settings through the Third-Party Service with
                  which you set up the recurring donation.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Indemnification</Text>
              <Text fontWeight={400} fontSize="md">
                To the fullest extent permitted by applicable law, you will
                indemnify, hold harmless, and, at RA’s request, defend RA and
                our officers, directors, agents, partners, and employees
                (individually and collectively, the “RA Parties”) from and
                against any losses, liabilities, claims, demands, damages,
                expenses or costs (“Claims”) from any third party arising out of
                or related to (a) your access to or use of the Services; (b)
                your User Content or Feedback; (c) your breach, or alleged
                breach, of these Terms or any other terms or policies applicable
                to your use of the Services; (d) your violation,
                misappropriation, or infringement of any rights of another
                (including intellectual property rights or privacy rights); (e)
                your conduct in connection with the Services; of (f) your
                failure to comply with applicable laws, rules, or regulations.
                You will promptly notify RA Parties of any third-party Claims,
                cooperate with RA Parties in defending such Claims, and pay all
                fees, costs, and expenses associated with defending such Claims
                (including attorneys' fees). The RA Parties will have control of
                the defense or settlement, at RA's sole option, of any
                third-party Claims. This indemnity is in addition to, and not in
                lieu of, any other indemnities set forth in a written agreement
                between you and RA or the other RA Parties.
              </Text>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Disclaimers</Text>
              <Text fontWeight={400} fontSize="md">
                Your use of our Services and any content or materials provided
                therein or therewith (including Third-Party Content, User
                Content of other users, and Third-Party Services) is at your
                sole risk. Except as otherwise provided in a writing by us and
                to the fullest extent permitted under applicable law, our
                Services and any content or materials provided therein or
                therewith (including Third-Party Content, User Content of other
                users, and Third-Party Services) are provided “as is” and “as
                available” without warranties of any kind, either express or
                implied. RA disclaims all warranties with respect to the
                foregoing, including implied warranties of merchantability,
                fitness for a particular purpose, title, and non-infringement,
                and any warranties from course of dealing or usage or trade. In
                addition, RA does not represent or warrant that our Services or
                any content provided therein or therewith (including Third-Party
                Content, User Content of other users, and Third-Party Services)
                are accurate, complete, reliable, current, or error-free or that
                access to our Services or any content provided therein or
                therewith (including Third-Party Content, User Content of other
                users, and Third-Party Services) will be uninterrupted. While RA
                attempts to make your use of our Services and any content
                provided therein or therewith (including Third-Party Content,
                User Content of other users, and Third-Party Services) safe, we
                cannot and do not represent or warrant that our Services or any
                content provided therein or therewith (including Third-Party
                Content, User Content of other users, and Third-Party Services)
                or our servers are free of viruses or other harmful components
                or content or materials. You assume the entire risk as to the
                quality and performance of the Services and any content provided
                therein or therewith (including Third-Party Content, User
                Content of other users, and Third-Party Services). All
                disclaimers of any kind (including in this section and elsewhere
                in these Terms) are made for the benefit of RA, the other RA
                Parties, and RA’s respective agents, representatives, licensors,
                suppliers, and service providers, as well as their respective
                successors and assigns.
              </Text>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Limitation of Liability</Text>
              <OrderedList fontWeight={400} fontSize="md">
                <ListItem my={3}>
                  To the fullest extent permitted by applicable law, RA and the
                  other RA Parties will not be liable to you under any theory of
                  liability—whether based in contract, tort, negligence, strict
                  liability, warranty, or otherwise—for any indirect,
                  consequential, exemplary, incidental, punitive, or special
                  damages or lost profits, or any loss of data, use, goodwill,
                  or other intangibles, even if RA or the other RA Parties have
                  been advised of the possibility of such damages.
                </ListItem>
                <ListItem my={3}>
                  The total liability of RA and the other RA Parties for any
                  claim arising out of or relating to these Terms or our
                  Services, regardless of the form of the action, is limited to
                  one hundred U.S. dollars ($100).
                </ListItem>
                <ListItem my={3}>
                  The limitations set forth in this Section 16 will not limit or
                  exclude liability for the gross negligence, fraud, or
                  intentional misconduct of RA or the other RA Parties or for
                  any other matters in which liability cannot be excluded or
                  limited under applicable law. Additionally, some jurisdictions
                  do not allow the exclusion or limitation of incidental or
                  consequential damages, so the above limitations or exclusions
                  may not apply to you.
                </ListItem>
              </OrderedList>{' '}
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Dispute Resolution; Binding Arbitration </Text>
              <Text fontWeight={700} fontSize="md" my={4}>
                PLEASE READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES YOU AND
                RA TO ARBITRATE CERTAIN DISPUTES AND CLAIMS AND LIMITS THE
                MANNER IN WHICH WE CAN SEEK RELIEF FROM EACH OTHER. ARBITRATION
                PRECLUDES YOU AND RA FROM SUING IN COURT OR HAVING A JURY TRIAL.
                YOU AND RA AGREE THAT ARBITRATION WILL BE SOLELY ON AN
                INDIVIDUAL BASIS AND NOT AS A CLASS ARBITRATION, CLASS ACTION,
                OR ANY OTHER KIND OF REPRESENTATIVE PROCEEDING. RA AND YOU ARE
                EACH WAIVING THE RIGHT TO TRIAL BY A JURY.
              </Text>
              <Text fontWeight={700} fontSize="md" my={4}>
                FOLLOW THE INSTRUCTIONS BELOW IF YOU WISH TO OPT OUT OF THE
                REQUIREMENT OF ARBITRATION ON AN INDIVIDUAL BASIS. NO CLASS OR
                REPRESENTATIVE ACTIONS OR ARBITRATIONS ARE ALLOWED UNDER THIS
                ARBITRATION AGREEMENT.
              </Text>
              <OrderedList fontWeight={400} fontSize="md">
                <ListItem my={3}>
                  For any dispute or claim that you have against RA, that RA has
                  against you or that you have, or RA has, in each case arising
                  from, relating to, or stemming from these Terms, our Services,
                  or any aspect of the relationship between you and RA as
                  relates to these Terms, our Services, including any privacy or
                  data security claims, (collectively, “Claims”, and each a
                  “Claim”), you and RA agree to attempt to first resolve the
                  Claim informally via the following process. If you assert a
                  Claim against RA, you will first contact RA by sending a
                  written notice of your Claim (“Claimant Notice”) to RA by
                  certified mail addressed to Reskill Americans, 5608 17th Ave.
                  NW Ste 619, Seattle, WA 98107 or by email to
                  info@reskillamericans.org. The Claimant Notice must (i)
                  include your name, residence address, email address, and
                  telephone number; (ii) describe the nature and basis of the
                  Claim; and (iii) set forth the specific relief sought. If RA
                  asserts a Claim against you, RA will first contact you by
                  sending a written notice of RA’s Claim (“RA Notice” and each
                  of a Claimant Notice and RA Notice, a “Notice”) to you via
                  email to the primary email address associated with your
                  account. The RA Notice must (i) include the name of a RA
                  contact and the contact’s email address and telephone number;
                  (ii) describe the nature and basis of the Claim; and (iii) set
                  forth the specific relief sought. If you and RA cannot reach
                  an agreement to resolve the Claim within thirty (30) days
                  after you or RA receives such a Notice, then either party may
                  submit the Claim to binding arbitration as set forth below.
                  The statute of limitations and any filing fee deadlines shall
                  be tolled for thirty (30) days from the date that either you
                  or RA first send the applicable Notice so that the parties can
                  engage in this informal dispute-resolution process.
                </ListItem>
                <ListItem my={3}>
                  Except for individual disputes that qualify for small claims
                  court and any disputes exclusively related to the intellectual
                  property or intellectual property rights of you or RA,
                  including any disputes in which you or RA seek injunctive or
                  other equitable relief for the alleged unlawful use of your or
                  RA’s intellectual property or other infringement of your or
                  RA’s intellectual property rights (“IP Claims”), all Claims,
                  whether based in contract, tort, statute, fraud,
                  misrepresentation, or any other legal theory, including Claims
                  that are not related to intellectual property or intellectual
                  property rights but are jointly filed with IP Claims, that are
                  not resolved in accordance with Section 17(a) will be resolved
                  by a neutral arbitrator through final and binding arbitration
                  instead of in a court by a judge or jury. Such Claims include,
                  without limitation, disputes arising out of or relating to
                  interpretation or application of this arbitration provision,
                  including the enforceability, revocability, or validity of the
                  arbitration provision or any portion of the arbitration
                  provision. The arbitrator will have the authority to grant any
                  remedy or relief that would otherwise be available in court.
                </ListItem>
                <ListItem my={3}>
                  These Terms affect interstate commerce, and the enforceability
                  of this Section 17 will be substantively and procedurally
                  governed by the Federal Arbitration Act, 9 U.S.C. § 1, et
                  seq., to the extent permitted by law.
                </ListItem>
                <ListItem my={3}>
                  All Claims must be submitted to the American Arbitration
                  Association (“AAA”) and will be resolved through binding
                  arbitration before one arbitrator. If you are a consumer, the
                  then-current version of the AAA’s Consumer Arbitration Rules
                  will apply, which are available on the AAA’s website
                  (adr.org), as amended by these Terms as follows:
                  <OrderedList>
                    <ListItem my={3}>
                      YOU AND RA AGREE THAT ANY ARBITRATION UNDER THESE TERMS
                      WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS
                      AND CLASS ACTIONS ARE NOT PERMITTED, AND YOU AND RA ARE
                      AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS
                      ACTION. The arbitrator may conduct only an individual
                      arbitration and may not consolidate more than one
                      individual’s claims, preside over any type of class or
                      representative proceeding, or preside over any proceeding
                      involving more than one individual.
                    </ListItem>
                    <ListItem my={3}>
                      For any arbitration you initiate, you will pay the
                      consumer filing fee, and RA will pay the remaining AAA
                      fees and costs. For any arbitration initiated by RA, RA
                      will pay all AAA fees and costs.
                    </ListItem>
                    <ListItem my={3}>
                      For all arbitrations where the claims asserted are $25,000
                      or less, the arbitration shall be resolved according to
                      the AAA’s Procedures for the Resolution of Disputes
                      through Document Submission, and for all other
                      arbitrations the following procedure will apply: (i) the
                      arbitrator will conduct hearings, if any, by
                      teleconference or videoconference rather than by personal
                      appearances, unless the arbitrator determines upon request
                      by you or by us that an in-person hearing is appropriate;
                      (ii) any in-person appearances will be held at a location
                      which is reasonably convenient to both parties with due
                      consideration of their ability to travel and other
                      pertinent circumstances; and (iii) if the parties are
                      unable to agree on a location, such determination should
                      be made by the AAA or by the arbitrator.
                    </ListItem>
                    <ListItem my={3}>
                      If you or RA submits a dispute to arbitration and the
                      arbitrator orders any exchange of information, you and RA
                      agree to cooperate to seek from the arbitrator protection
                      for any confidential, proprietary, trade secret, or
                      otherwise sensitive information, documents, testimony,
                      and/or other materials that might be exchanged or the
                      subject of discovery in the arbitration. You and RA agree
                      to seek such protection before any such information,
                      documents, testimony, and/or materials are exchanged or
                      otherwise become the subject of discovery in the
                      arbitration.{' '}
                    </ListItem>
                    <ListItem my={3}>
                      The arbitrator’s decision will follow these Terms and will
                      be final and binding. The arbitrator will have authority
                      to award temporary, interim, or permanent injunctive
                      relief or relief providing for specific performance of
                      these Terms but only to the extent necessary to provide
                      relief warranted by the individual claim before the
                      arbitrator. The award rendered by the arbitrator may be
                      confirmed and enforced in any court having jurisdiction
                      thereof. Notwithstanding any of the foregoing, nothing in
                      these Terms will preclude you from bringing issues to the
                      attention of federal, state, or local agencies and, if the
                      law allows, they can seek relief against us for you.
                    </ListItem>
                  </OrderedList>
                </ListItem>
                <ListItem my={3}>
                  One Year to Assert Claims. To the extent permitted by law, any
                  Claim by you or RA relating in any way to these Terms our
                  Services, or any aspect of the relationship between you and RA
                  as relates to these Terms or our Services, must be filed
                  within one year after such Claim arises; otherwise, the Claim
                  is permanently barred, which means that you and RA will not
                  have the right to assert the Claim.
                </ListItem>
                <ListItem my={3}>
                  You have the right to opt out of binding arbitration within 30
                  days of the date you first accepted these Terms by providing
                  us with notice of your decision to opt-out via email at
                  info@reskillamericans.org or by certified mail addressed to
                  Reskill Americans, 5608 17th Ave. NW Ste 619, Seattle, WA
                  98107. In order to be effective, the opt-out notice must
                  include your full name, mailing address, and email address.
                  The notice must also clearly indicate your intent to opt out
                  of binding arbitration. By opting out of binding arbitration,
                  you are agreeing to resolve disputes in accordance with
                  Section 18.
                </ListItem>
                <ListItem my={3}>
                  If any portion of this Section 17 is found to be unenforceable
                  or unlawful for any reason, including but not limited to
                  because it is found to be unconscionable, (i) the
                  unenforceable or unlawful provision will be severed from these
                  Terms; (ii) severance of the unenforceable or unlawful
                  provision will have no impact whatsoever on the remainder of
                  this Section 17 or the parties’ ability to compel arbitration
                  of any remaining claims on an individual basis pursuant to
                  this Section 17; and (iii) to the extent that any claims must
                  therefore proceed on a class, collective, consolidated, or
                  representative basis, such claims must be litigated in a civil
                  court of competent jurisdiction and not in arbitration. The
                  litigation of those claims will be stayed pending the outcome
                  of any individual claims in arbitration. Further, if any part
                  of this Section 17 is found to prohibit an individual claim
                  seeking public injunctive relief, that provision will have no
                  effect to the extent such relief is allowed to be sought out
                  of arbitration, and the remainder of this Section 17 will be
                  enforceable.
                </ListItem>
                <ListItem my={3}>
                  Notwithstanding anything to the contrary in the Terms, if you
                  reside in any country outside of the United States, you may
                  bring legal proceedings regarding the Terms either by
                  following the arbitration procedure detailed above in this
                  Section 17 of the Terms or, if given the right by applicable
                  law, by submitting the dispute to an arbitration administrator
                  in the jurisdiction in which you reside. To the extent any
                  proceeding is not subject to arbitration under applicable law,
                  you may submit the dispute to the courts of the jurisdiction
                  in which you reside.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Governing Law</Text>
              <Text fontWeight={400} fontSize="md">
                Any Claims will be governed by and construed and enforced in
                accordance with the laws of the State of Washington, except to
                the extent preempted by U.S. Federal Law, without regard to
                conflict of law rules or principles (whether of the State of
                Washington or any other jurisdiction) that would cause the
                application of the laws of any other jurisdiction. If any Claim
                is not subject to arbitration pursuant to Section 17, then the
                state and federal courts located in King County, Washington,
                will have exclusive jurisdiction. You and RA waive any objection
                to venue in any such courts. If your local law requires that
                consumer contracts be interpreted subject to local law and
                enforced in the courts of that jurisdiction, this section may
                not apply to you only to the extent that local law conflicts
                with this section.
              </Text>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Modifying and Terminating Our Services</Text>
              <Text fontWeight={400} fontSize="md">
                We reserve the right to modify our Services or to suspend or
                terminate providing all or part of our Services at any time;
                charge, modify, or waive any fees required to use the Services;
                or offer opportunities to some or all end users of the Services.
                We may provide you with notice in advance of the suspension or
                discontinuation of all or part of our Services, such as by
                sending an email or providing a notice through our Services. All
                modifications and additions to the Services will be governed by
                the Terms or Supplemental Terms, unless otherwise expressly
                stated by RA in writing. You also have the right to stop using
                our Services at any time, and you may terminate these Terms by
                ceasing use of our Services. We are not responsible for any loss
                or harm related to your inability to access or use our Services.
                We reserve the right to terminate your use of our Services at
                any time.
              </Text>
            </ListItem>

            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Severability</Text>
              <Text fontWeight={400} fontSize="md">
                If any portion of these Terms other than Section 17 is found to
                be unenforceable or unlawful for any reason, including but not
                limited to because it is found to be unconscionable, (a) the
                unenforceable or unlawful provision will be severed from these
                Terms; (b) severance of the unenforceable or unlawful provision
                will have no impact whatsoever on the remainder of these Terms;
                and (c) the unenforceable or unlawful provision may be revised
                to the extent required to render the Terms enforceable or valid,
                and the rights and responsibilities of the parties will be
                interpreted and enforced accordingly, so as to preserve the
                Terms and the intent of the Terms to the fullest possible
                extent.
              </Text>
            </ListItem>
            <ListItem my={3} fontWeight={600} fontSize="xl">
              <Text>Miscellaneous</Text>
              <OrderedList fontWeight={400} fontSize="md">
                <ListItem my={3}>
                  {' '}
                  RA’s failure to exercise or enforce any right or provision of
                  these Terms will not operate as a waiver of such right or
                  provision. These Terms reflect the entire agreement between
                  the parties relating to the subject matter hereof and
                  supersede all prior agreements, representations, statements,
                  and understandings of the parties. The section titles in these
                  Terms are for convenience only and have no legal or
                  contractual effect. Use of the word “including” will be
                  interpreted to mean “including without limitation.” Except as
                  otherwise provided herein, these Terms are intended solely for
                  the benefit of the parties and are not intended to confer
                  third-party beneficiary rights upon any other person or
                  entity. Communications and transactions between us may be
                  conducted electronically.
                </ListItem>
                <ListItem my={3}>
                  If you have a question or complaint regarding the Services,
                  please send an email to info@reskillamericans.org. You may
                  also contact us by writing to Reskill Americans, 5608 17th
                  Ave. NW Ste 619, Seattle, WA 98107. Please note that email
                  communications will not necessarily be secure; accordingly,
                  you should not include payment card information or other
                  sensitive information in your email correspondence with us.{' '}
                </ListItem>
              </OrderedList>{' '}
            </ListItem>
          </OrderedList>
        </Flex>
      </Box>
    </>
  );
};

export default Terms;
