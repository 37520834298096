import { useEffect, useRef, useState } from 'react';

import { AspectRatio, Flex } from '@chakra-ui/react';

const Embed = () => {
  const iframeRef = useRef(null);

  const [iframeHeight, setIframeHeight] = useState('');

  useEffect(() => {
    const currentWidth = iframeRef.current.clientWidth;
    const calculatedHeight = currentWidth / 0.433333333;
    setIframeHeight(calculatedHeight);
  }, []);

  return (
    <Flex flexDir="column" maxW={640}>
      <AspectRatio ratio={13 / 30} h={[2100, iframeHeight]}>
        <iframe
          title="ReskillAmericans Survey"
          src="https://docs.google.com/forms/d/e/1FAIpQLSchTBavWfiiJ1ZrygUjQqbvd-AWOq_OqwXEg8jorTX9E23apA/viewform?embedded=true"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          ref={iframeRef}
        />
      </AspectRatio>
    </Flex>
  );
};

export default Embed;
