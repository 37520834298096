import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import Form from './Form';

const Welcome = () => {
  return (
    <Box w="full">
      <Flex
        flexDir="column"
        maxW={1200}
        margin="0 auto"
        px={[6, 8]}
        py={[8, 16]}
      >
        <Heading my={4} size="lg">
          We've updated our Terms of Service and Privacy Policy
        </Heading>
        <Text fontWeight={600} fontSize="xl">
          We have made some changes to our Terms of Service and Privacy Policy
          that are effective as of November 14, 2022.
        </Text>
        <Form />
      </Flex>
    </Box>
  );
};

export default Welcome;
