import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Checkbox,
  Link,
  Button,
  FormErrorMessage,
  InputGroup,
  Spinner,
  InputRightElement,
  FormHelperText,
  Icon,
  Tooltip,
  Heading,
  Text,
  Flex,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from '@chakra-ui/react';
import toast from 'react-hot-toast';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import Embed from './Embed';

const Form = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressError, setEmailAddressError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [surveyValidating, setSurveyValidating] = useState(false);
  const [surveyError, setSurveyError] = useState(false);
  const [oktaError, setOktaError] = useState(false);
  const [oktaVerified, setOktaVerified] = useState(false);

  const [hasNotReadTos, setHasNotReadTos] = useState(true);
  const [hasNotConsented, setHasNotConsented] = useState(false);
  const [hasNotDoneSurvey, setHasNotDoneSurvey] = useState(true);
  const [canNotWork, setCanNotWork] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  // const postFormUrl='https://login.reskillamericans.org/login/preview?x3rf5=79d91eefed35f90f98d6e2fe9bba61727a95b8d710261371c390e918ad7fea7c';
  const postFormUrl =
    'https://login.reskillamericans.org/app/UserHome?iss=https%3A%2F%2Flogin.reskillamericans.org&session_hint=AUTHENTICATED';

  const cookieName = 'b347YGF8';
  const cookieValue =
    '79d91eefed35f90f98d6e2fe9bba61727a95b8d710261371c390e918ad7fea7c';

  // set the cookie
  const setCookie = () => {
    document.cookie = `${cookieName}=${cookieValue};SameSite=None;Secure;expires=604800;`;
  };

  const handleCheckbox = (event, setter) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setter(false);
    } else {
      setter(true);
    }
  };

  const validateEmail = mail => {
    if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };

  const userValidation = () => {
    if (validateEmail(emailAddress)) {
      setSurveyValidating(true);

      setSurveyError(false);

      fetch(`https://api.reskillamericans.org/tos/okta/${emailAddress}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong');
        })
        .then(() => {
          setOktaVerified(true);
          setOktaError(false);

          return fetch(
            `https://api.reskillamericans.org/tos/survey/${emailAddress}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
            .then(response => {
              if (response.ok) {
                return response.json();
              }
              throw new Error('Something went wrong');
            })
            .then(() => {
              setSurveyValidating(false);
              setHasNotDoneSurvey(false);
            })
            .catch(() => {
              setSurveyValidating(false);
              setHasNotDoneSurvey(true);
              setSurveyError(true);
              // setEmailAddress('');
            });
        })
        .catch(() => {
          setSurveyValidating(false);
          setOktaError(true);
        });
      return true;
    } else {
      setEmailAddressError(true);
      return false;
    }
  };

  const submitParticipant = data => {
    fetch('https://api.reskillamericans.org/tos/terms-of-service', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(() => {
        toast.success('Sent successfully!');
        setLoading(false);
        setEmailAddress('');
        onOpen();
        setTimeout(() => {
          window.location.href = postFormUrl;
        }, 3000);
      })
      .catch(() => {
        toast.error('There was was an issue.');
        setLoading(false);
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    const participantData = {
      email: emailAddress,
      tos_accepted: true,
      consent: !hasNotConsented,
    };

    setCookie();

    // submit user
    submitParticipant(participantData);
  };

  const resetState = () => {
    setEmailAddressError(false);
    setLoading(false);
    setSurveyValidating(false);
    setSurveyError(false);
    setOktaError(false);
    setOktaVerified(false);
    setHasNotDoneSurvey(true);
  };

  useEffect(() => {
    // function to find cookie
    const checkCookie = () => {
      var match = document.cookie.match(
        RegExp('(?:^|;\\s*)' + cookieName + '=([^;]*)')
      );
      return match ? match[1] : null;
    };

    // check if user has read TOS (checks for cookie)
    const checkIfUserHasReadTos = () => {
      // if cookie exists ...
      if (checkCookie() !== null) {
        // ... redirect the user
        onOpen();
        setTimeout(() => {
          window.location.href = postFormUrl;
        }, 3000);
      }
    };
    checkIfUserHasReadTos();
  }, [onOpen]);

  return (
    <Box as="form" onSubmit={handleSubmit} mb={5}>
      <FormControl mt={7}>
        <Checkbox
          onChange={e => handleCheckbox(e, setHasNotReadTos)}
          borderColor="gray.200"
          padding={3}
          px={0}
        >
          By checking the box to the left, you agree to our{' '}
          <Link
            href="/terms"
            isExternal
            color="blue"
            textDecor="underline"
            _hover={{ textDecor: 'none' }}
          >
            Terms of Service
          </Link>
          {'  '} and acknowledge having read our {'  '}
          <Link
            href="/privacy"
            isExternal
            color="blue"
            textDecor="underline"
            _hover={{ textDecor: 'none' }}
          >
            Privacy Policy
          </Link>
          .
        </Checkbox>
        <Checkbox
          onChange={e => handleCheckbox(e, setHasNotConsented)}
          borderColor="gray.200"
          padding={3}
          px={0}
        >
          You authorize Reskill Americans to share your personal information
          with companies who might be looking for job candidates with your
          skills.
        </Checkbox>
      </FormControl>

      <FormControl
        isRequired
        isInvalid={oktaError || surveyError}
        maxW={['full', 400]}
        mt={5}
      >
        <FormLabel>Email address</FormLabel>
        <Flex>
          <InputGroup w="full">
            <Tooltip
              label="Enter a valid email address"
              closeDelay={500}
              placement="top-start"
              isOpen={emailAddressError}
            >
              <Input
                type="email"
                placeholder="example@email.com"
                _placeholder={{ opacity: 0.5, color: 'gray.600' }}
                value={emailAddress}
                onChange={e => setEmailAddress(e.target.value)}
                borderColor="gray.200"
                // onBlur={userValidation}
                onFocus={resetState}
                h={[14, 10]}
              />
            </Tooltip>

            {surveyValidating && (
              <InputRightElement
                h="full"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner />
              </InputRightElement>
            )}
            {!hasNotDoneSurvey && (
              <InputRightElement
                h="full"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FiCheckCircle} color="green" boxSize={6} />
              </InputRightElement>
            )}
            {(oktaError || surveyError) && (
              <InputRightElement
                h="full"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FiXCircle} color="red" boxSize={6} />
              </InputRightElement>
            )}
          </InputGroup>
          <Button
            ml={3}
            onClick={userValidation}
            colorScheme="blue"
            variant="solid"
          >
            Validate
          </Button>
        </Flex>

        {oktaError ? (
          <FormErrorMessage color="red.300">
            We do not recognize you by this email address!
          </FormErrorMessage>
        ) : surveyError ? (
          <FormErrorMessage color="red.300">
            Please fill the survey to proceed.
          </FormErrorMessage>
        ) : (
          <FormHelperText color="gray.600">
            Enter your SSO (Okta) email address.
          </FormHelperText>
        )}
      </FormControl>

      <FormControl isRequired>
        <Checkbox
          onChange={e => handleCheckbox(e, setCanNotWork)}
          borderColor="gray.200"
          padding={3}
          px={0}
        >
          By checking this box you acknowledge you are legally authorized to
          work in the U.S.
        </Checkbox>
      </FormControl>

      <Button
        disabled={hasNotReadTos || hasNotDoneSurvey || canNotWork}
        colorScheme="blue"
        my={4}
        mt={6}
        w="full"
        h={[14, 10]}
        variant="solid"
        type="submit"
        isLoading={loading}
        loadingText="Submitting"
        maxW={['full', 400]}
      >
        Submit
      </Button>

      {oktaVerified && (
        <Box my={5} mt={10} display={hasNotDoneSurvey ? 'block' : 'none'}>
          <Heading my={4} size="lg">
            Please complete the survey below.
          </Heading>
          <Text mb={5}>
            *Remember to enter the email you use to log into Okta.
          </Text>
          <Embed />
        </Box>
      )}

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Notice!</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            You are verified. Redirecting to Okta...
          </AlertDialogBody>
          <AlertDialogFooter></AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  );
};

export default Form;
