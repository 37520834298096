import {
  Box,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import useDocumentTitle from '../hook/useDocumentTitle';

const Privacy = () => {
  useDocumentTitle('Reskill Americans Privacy Policy');

  const UList = ({ list }) => {
    return (
      <UnorderedList>
        {list?.map(({ title, desc }, i) => {
          return (
            <ListItem key={i}>
              <strong>{title}</strong> {desc}
            </ListItem>
          );
        })}
      </UnorderedList>
    );
  };

  const SubSection = ({ subheading, contentList, list }) => {
    return (
      <Box>
        {subheading && (
          <Heading fontSize="xl" mt={5} mb={3}>
            {subheading}
          </Heading>
        )}
        {contentList?.map((content, i) => {
          return (
            <Text key={i} mt={3} mb={4}>
              {content || 'content'}{' '}
            </Text>
          );
        })}
        {list && <UList list={list} />}
      </Box>
    );
  };

  const Section = ({
    heading,
    note,
    subheading,
    contentList,
    list,
    children,
  }) => {
    return (
      <Box mb={4}>
        <Heading fontSize="2xl" my={5}>
          {heading}
        </Heading>
        {note && (
          <Text fontStyle="italic" fontSize="sm" fontWeight={700} my={4}>
            {note}
          </Text>
        )}
        <SubSection
          subheading={subheading}
          contentList={contentList}
          list={list}
        />
        {children}
      </Box>
    );
  };

  return (
    <Box w="full">
      <Flex
        flexDir="column"
        maxW={1200}
        margin="0 auto"
        px={[6, 8]}
        py={[8, 16]}
      >
        <Heading textAlign="center" fontSize="2xl">
          RESKILL AMERICANS PRIVACY POLICY
        </Heading>
        <Section
          note="Last Updated: November 14th, 2022"
          contentList={[
            `This Privacy Policy describes how Reskill Americans (collectively, “RA,” “we,” or
          “us”) collect, use, and disclose information about you. This Privacy Policy applies to
          information we collect when you access or use our websites, collaboration tools,
          learning-management systems, and other online products and services (including
          those provided by third parties that are made available to you through our platform)
          that link to this Privacy Policy (collectively, our “Services”), or when you otherwise
          interact with us, such as through our courses, training, skilling, internships, or job
          placement support. We may provide different or additional notices of our privacy
          practices with respect to certain products or services, in which case this Privacy
          Policy will not apply.`,
            `We may change this Privacy Policy from time to time. If we make changes, we will
          notify you by revising the date at the top of this policy. We may also provide you
          with additional notice (such as by adding a statement to the Services or sending
          you a notification), such as in connection with making material changes to this
          Privacy Policy. We encourage you to review this Privacy Policy regularly to stay
          informed about our information practices and the choices available to you.`,
          ]}
        />
        <Section
          heading="CONTENTS"
          list={[
            { desc: 'Collection of Information' },
            { desc: 'Use of Information' },
            { desc: 'Sharing of Information' },
            { desc: 'Analytics' },
            {
              desc: 'Transfer of Information to the United States and Other Countries',
            },
            { desc: 'Your Choices' },
            { desc: 'Contact Us' },
          ]}
        />
        <Section
          heading="COLLECTION OF INFORMATION"
          subheading="Information You Provide to Us"
          contentList={[
            `We collect information you provide directly to us. For example, we collect information directly from you
            when you create an account, submit or post content through our Services, use of our Services, contact
            our staff, respond to questionnaires, or otherwise interact with us. The types of information about you
            that we may collect from you vary depending on the Services you use, but can include your name,
            username, profile images, email address, phone number, postal address, state of residence, US work-
            authorization status, racial identity, age, employment status, links to your coding and social media
            accounts, and answers to survey questions (such as how you heard about us) and any other information
            you choose to provide.`,
            `Live classes available through the Services may enable instructors to record (including via third-party
            services, such as Zoom), share, and upload classes, in which case RA will collect the audio and video
            content that instructors choose to upload. The recording that instructors upload may include the voices and video recordings of instructors and students participating in the live class, and such recordings may
            be made available to other users of the Services.`,
            `Additionally, if you make a purchase, we work with a third-party payment processor to collect and
            process your payment information.`,
          ]}
        >
          <SubSection
            subheading="Information We Collect Automatically"
            contentList={[
              `We automatically collect certain information about your interactions with us or our Services, including:`,
            ]}
            list={[
              {
                title: 'Activity Information: ',
                desc: `We collect information about your activity on our Services, such as when you
                participate in our courses, projects, internships, or other educational offerings, or when we record
                classes.`,
              },
              {
                title: 'Transactional Information: ',
                desc: `When you make a purchase or return, we collect information about the
                transaction, such as product details, purchase price, and the date and location of the transaction.`,
              },
              {
                title: 'Device and Usage Information: ',
                desc: `We collect information about how you access our Services, including
                data about the device and network you use, such as your hardware model, operating system
                version, IP address, unique device identifiers, and browser type. We also collect information about
                your activity on our Services, such as access times, pages viewed, links clicked, and the page you
                visited before navigating to our Services.`,
              },
              {
                title: 'Your Content: ',
                desc: `With your permission, we collect information and content stored on your device,
                such as your assignment submissions.`,
              },
              {
                title:
                  'Information Collected by Cookies and Similar Tracking Technologies: ',
                desc: `We (and those who perform
                  work for us) use tracking technologies, such as cookies and web beacons, to collect information
                  about your interactions with the Services. Cookies are small data files stored on your hard drive or in
                  device memory that help us improve our Services and your experience, see which areas and features
                  of our Services are popular, and count visits. Web beacons (also known as “pixel tags” or “clear
                  GIFs”) are electronic images that we use on our Services and in our emails to help deliver cookies,
                  count visits, and understand usage and campaign effectiveness. For more information about cookies
                  and how to disable them, see the Your Choices section below.`,
              },
            ]}
          />
          <SubSection
            subheading="Information We Collect from Other Sources"
            contentList={[
              `We obtain information from other sources, such as the third parties that provide services through our
              platform (e.g., Zoom and Slack). Additionally, if you create or log into your RA account through a third-
              party platform (such as LinkedIn), we will have access to certain information from that platform, such as
              your name, email address, and profile picture, in accordance with the authorization procedures
              determined by such platform.`,
            ]}
          />
          <SubSection
            subheading="Information We Derive"
            contentList={[
              `We may derive information or draw inferences about you based on the information we collect. For
              example, we may make inferences about your performance on our assignments and other evaluations
              of your academic performance and your suitability for employment, projects, and internships based on
              your grades and/or level of progress.`,
            ]}
          />
        </Section>
        <Section
          heading="USE OF INFORMATION"
          contentList={[`We use the information we collect to:`]}
          list={[
            {
              desc: `Provide, maintain, improve, and develop new products and services, including to debug and repair
              errors in our Services;`,
            },
            {
              desc: `Personalize your experience with us;`,
            },
            {
              desc: `Send you technical notices, security alerts, support messages and other transactional or relationship
              messages;`,
            },
            {
              desc: `Communicate with you about products, services, and events offered by RA and others and provide
              news and information that we think will interest you (see the Your Choices section below for
              information about how to opt out of these communications at any time);`,
            },
            {
              desc: `Monitor and analyze trends, usage, and activities in connection with our products and services;`,
            },
            {
              desc: `Facilitate contests, sweepstakes, and promotions and process and deliver entries and rewards;`,
            },
            {
              desc: `Detect, investigate, and help prevent security incidents and other malicious, deceptive, fraudulent, or
              illegal activity and help protect the rights and property of RA and others;`,
            },
            {
              desc: `Comply with our legal and financial obligations;`,
            },
            {
              desc: `Create de-identified, anonymized or aggregated information; and`,
            },
            {
              desc: `Carry out any other purpose described to you at the time the information was collected.`,
            },
          ]}
        />
        <Section
          heading="SHARING OF INFORMATION"
          contentList={[
            `We share personal information in the following circumstances or as otherwise described in this policy:`,
          ]}
          list={[
            {
              desc: `We share personal information with vendors, service providers, contractors and consultants that
              access personal information to perform work for us, such as companies that assist us with web
              hosting, payment processing, and marketing.`,
            },
            {
              desc: `If you share content on our Services, we share this information publicly/with other users of our
              Services.`,
            },
            {
              desc: `If you choose to use integrations we offer on our Services, such as signing in with LinkedIn, we may
              share certain information with the integration partners.`,
            },
            {
              desc: `If you are a student, we may share personal information with third parties, such as companies
              seeking job candidates, for their own purposes.`,
            },
            {
              desc: `We may disclose personal information if we believe that disclosure is in accordance with, or required
              by, any applicable law or legal process, including lawful requests by public authorities to meet
              national security or law enforcement requirements.`,
            },
            {
              desc: `We may share personal information if we believe that your actions are inconsistent with our user
              agreements or policies, if we believe that you have violated the law, or if we believe it is necessary to
              protect the rights, property, and safety of RA, our users, the public, or others.`,
            },
            {
              desc: `We share personal information with our lawyers and other professional advisors where necessary to
              obtain advice or otherwise protect and manage our business interests.`,
            },
            {
              desc: `We may share personal information in connection with, or during negotiations concerning, any
              merger, sale of company assets, financing, or acquisition of all or a portion of our business by another
              company.`,
            },
            {
              desc: `Personal information is shared between and among RA and our current and future parents, affiliates,
              and subsidiaries and other companies under common control and ownership.`,
            },
            {
              desc: `We share personal information with your consent or at your direction.`,
            },
            {
              desc: `We also share aggregated or de-identified information that cannot reasonably be used to identify
              you.`,
            },
          ]}
        />
        <Section
          heading="ANALYTICS"
          contentList={[
            `We engage others to provide analytics services across the web. These entities may use cookies, web
          beacons, device identifiers, and other technologies to collect information about your use of our Services
          and other websites and applications, including your IP address, web browser, pages viewed, time spent
          on pages, links clicked, and conversion information. This information may be used by RA and others to,
          among other things, analyze and track data, determine the popularity of certain content, and better
          understand your online activity.`,
          ]}
        />
        <Section
          heading="TRANSFER OF INFORMATION TO THE UNITED STATES AND OTHER COUNTRIES"
          contentList={[
            `RA is headquartered in the United States and we have operations and vendors in the United States and
          other countries. Therefore, we and those that perform work for us may transfer your personal
          information to, or store or access it in, jurisdictions that may not provide levels of data protection that
          are equivalent to those of your home jurisdiction.`,
          ]}
        />
        <Section heading="YOUR CHOICES">
          <SubSection
            subheading="Account Information"
            contentList={[
              `You may update and correct certain account information by signing into your account and updating your
              profile.`,
            ]}
          />
          <SubSection
            subheading="Cookies"
            contentList={[
              `Most web browsers are set to accept cookies by default. If you prefer, you can usually adjust your
              browser settings to remove or reject browser cookies. Please note that removing or rejecting cookies
              could affect the availability and functionality of our Services.`,
            ]}
          />
          <SubSection
            subheading="Communications Preferences"
            contentList={[
              `You may opt out of receiving text messages or promotional emails from RA by following the instructions
              in those communications. If you opt out, we may still send you non-promotional emails, such as those
              about your account or our ongoing business relations.`,
            ]}
          />
        </Section>
        <Section
          heading="CONTACT US"
          contentList={[
            `If you have any questions about this Privacy Policy, please contact us at 
            info@reskillamericans.org`,
          ]}
        />
        {/* <Section
          heading="heading"
          subheading="subheading"
          contentList={[``]}
          list={[{ desc: `` }]}
        /> */}
      </Flex>
    </Box>
  );
};

export default Privacy;
